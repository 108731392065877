<template>
  <div style="background-color: #f6f8fa">
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>

        <v-layout wrap justify-center pa-5 style="background-color: #FFFFFF;">
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md6 sm6 lg6 class="text-left" align-self-center>
                <span class="heading" style="color: rgba(0, 0, 0, 0.8)"
                  >Dashboard</span
                >
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 class="text-right">
                <v-layout wrap justify-end>
                  <v-flex xs12 lg4 sm4 md4>
                    <v-select
                      :items="dateitems"
                      dense
                      hide-details="true"
                      solo
                      flat
                      background-color="#D1E0FCB2"
                      prepend-inner-icon="mdi-calendar-text"
                      v-model="datefilter"
                      class="custom-select-background dropdown"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                md3
                sm3
                lg3
                v-for="(card, index) in cardData"
                :key="index"
                pa-1
              >
                <v-card elevation="3">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs3>
                          <v-layout wrap justify-start>
                            <v-flex xs12 text-left>
                              <v-img :src="card.image"></v-img>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs9 pl-2>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="cardheader">{{ card.title }}</span>
                            </v-flex>
                            <v-flex xs12>
                              <span class="cardvalue">{{ card.value }}</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12 md12 lg4>
                <v-card elevation="1" height="398px">
                  <v-layout wrap justify-start px-5 pt-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Conflict Reports by Animal Type</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-2
                          ><span class="reporttwo"
                            >Detailed Breakdown of Incidents Across
                          </span></v-flex
                        >
                        <v-flex xs12 pt-3
                          ><span class="reportthree"
                            >Total Conflicts Reported</span
                          ></v-flex
                        >
                        <v-flex xs12
                          ><span class="reportfour">{{ totalconflictdata }}</span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start>
                    <v-flex xs12 v-if="flag">
                      <apexchart
                        width="380"
                        height="225px"
                        type="donut"
                        :options="options"
                        :series="series"
                      ></apexchart>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md12 lg8 pl-lg-2 pt-sm-md-2>
                <v-card elevation="1">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Animal Conflict Reports by District Type</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-2
                          ><span class="reporttwo"
                            >Detailed Breakdown of Incidents Across
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-4>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg3>
                          <v-card
                            elevation="0"
                            style="background-color: #f7f7f7"
                          >
                            <v-layout wrap justify-start pa-5>
                              <v-flex xs12>
                                <span class="reportfive"
                                  >Total conflicts Reported</span
                                >
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex
                                    xs12
                                    sm6
                                    md6
                                    lg6
                                    class="text-left"
                                    align-self-start
                                  >
                                    <v-img
                                      :src="
                                        require('@/assets/images/mapicon.png')
                                      "
                                      contain
                                      height="45px"
                                    ></v-img
                                  ></v-flex>
                                  <v-flex
                                    xs12
                                    sm6
                                    md6
                                    lg6
                                    class="text-right"
                                    align-self-center
                                    ><span class="reportsix">63</span></v-flex
                                  >
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#786D17"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Idukki</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#B83E89"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Malappuram</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#9B6AD0"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kollam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#22D6C4"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Ernakulam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#563CF0"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Wayanad</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#EE7D47"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kasaragod</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-1 pb-3>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg3>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#FB38BB"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kozhikode</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#6BDA7C"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Alappuzha</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#F05429"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kottayam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#EDED59"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Thrissur</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#CD186B"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kannur</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#7D0460"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Trivandrum</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg3 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#76CC1C"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Pathanamthitta</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#8ABC30"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Palakkad</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span class="districtvalue"
                                      >2</span
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12 md12 lg6>
                <v-card elevation="1">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Year-wise Animal Conflict Reports</span
                          ></v-flex
                        >
                        <v-flex xs12
                          ><span class="reporttwo"
                            >Annual breakdown of reported animal conflicts,
                            categorized by year
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-layout wrap justify-start>
                        <v-flex
                          xs12
                          lg4
                          v-for="(item, index) in yearData"
                          :key="index"
                          pa-1
                        >
                          <v-card
                            style="background-color: #f6f6f6"
                            elevation="0"
                          >
                            <v-layout wrap justify-center pa-5>
                              <v-flex
                                xs12
                                class="text-center yearhead"
                                align-self-center
                                style="color: #6594f8"
                                pa-2
                              >
                                <v-icon color="#6594F8">{{ item.icon }}</v-icon
                                ><span>{{ item.year }}</span>
                              </v-flex>
                              <v-flex xs12><v-divider></v-divider></v-flex>
                              <v-flex xs12 pa-2 class="text-center yearsub">{{
                                item.value
                              }}</v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md12 lg6 pl-lg-2 pt-md-sm-2>
                <v-card elevation="1">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 lg9>
                          <v-layout wrap justify-start>
                            <v-flex>
                              <span class="reportone"
                                >Animal Conflicts by Threat Level</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 lg3>
                          <v-select
                            outlined
                            dense
                            :hide-details="true"
                            :items="yearitems"
                            v-model="lineyeardata"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12
                          ><span class="reporttwo"
                            >shows a visual breakdown of animal conflicts by
                            threat level.
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-layout wrap justify-start>
                        <v-flex xs12 v-if="flag1">
                          <apexchart
                            type="line"
                            height="248px"
                            :options="linechartoptions"
                            :series="linechartseries"
                          ></apexchart>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      flag:false,
      flag1:false,
      dateitems: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "Last 60 days",
        "Last 90 days",
      ],
      options: {
        chart: {
          type: "donut",
        },
        labels: [], // For the animal names
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [],
      datefilter: "Last 30 days",
      cardData: [
        {
          image: require("@/assets/images/humandeath.png"),
          title: "Total Human Deaths",
          value: "",
        },
        {
          image: require("@/assets/images/animaldeath.png"),
          title: "Total Animal Deaths",
          value: "",
        },
        {
          image: require("@/assets/images/humaninjury.png"),
          title: "Total Human Injury ",
          value: "",
        },
        {
          image: require("@/assets/images/animalcapptured.png"),
          title: "Total Animals captured",
          value: "",
        },
        {
          image: require("@/assets/images/animalreleased.png"),
          title: "Total Animals released",
          value: "",
        },
      ],
      yearData: [
        {
          year: 2024,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2023,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2022,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2021,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2020,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2019,
          value: 5234,
          icon: "mdi-calendar",
        },
      ],
      linechartseries: [
        {
          name: "Low threat",
          data: [50, 40, 60, 70, 80],
        },
        {
          name: "Moderate threat",
          data: [30.2, 30, 10, 50, 60],
        },
        {
          name: "High threat",
          data: [20, 25, 45, 67, 30],
        },
      ],
      linechartoptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#07E098", "#F6D635", "#F33232"],
      },
      yearitems: ["2023", "2024"],
      lineyeardata: "2024",
      totalAnimalDeaths: "",
      totalReleased: "",
      totalCaptured: "",
      totalHumanDeaths: "",
      totalHumanInjuries: "",
      totalconflictdata:"",

    };
  },
  beforeMount() {
    this.getData();
    this.animalcountData();
    this.threatlevelData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/dashboard/count",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.cardData[0].value = response.data.data.totalHumanDeaths;
            this.cardData[1].value = response.data.data.totalAnimalDeaths;
            this.cardData[2].value = response.data.data.totalHumanInjuries;
            this.cardData[3].value = response.data.data.totalCaptured;
            this.cardData[4].value = response.data.data.totalReleased;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    animalcountData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/animalsummary/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const animalReportSummary = response.data.data.animalReportSummary;
            this.series = animalReportSummary.map((item) => item.totalReports);
            this.options.labels = animalReportSummary.map((item) => item.name);
            this.totalconflictdata = response.data.data.totalReports;
            this.flag=true
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    threatlevelData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/threatlevel/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const threatData = response.data.data;
            const months = threatData.map(item => item.month);
            const highThreat = threatData.map(item => {
            const level = item.threatLevels.find(level => level.threatlevel === "High");
            return level ? level.totalReports : 0;
          });
          const mediumThreat = threatData.map(item => {
            const level = item.threatLevels.find(level => level.threatlevel === "Medium");
            return level ? level.totalReports : 0;
          });
          const lowThreat = threatData.map(item => {
            const level = item.threatLevels.find(level => level.threatlevel === "Low");
            return level ? level.totalReports : 0;
          });
          this.linechartseries = [
            { name: "High threat", data: highThreat },
            { name: "Moderate threat", data: mediumThreat },
            { name: "Low threat", data: lowThreat },
          ];
          this.linechartoptions = {
            ...this.linechartoptions,
            xaxis: {
              categories: months, // Months as x-axis labels
            },
          };
            this.flag1=true
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  <style>
.heading {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.dropdown {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
}
.custom-select-background .v-input__control {
  background-color: rgba(209, 224, 252, 0.7);
}
.cardheader {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* opacity: 50%; */
  color: #797979;
}
.cardvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.reportone {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.reporttwo {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 1);
  /* opacity: 50%; */
  color: #797979;
}
.reportthree {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.reportfour {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 35px;
  line-height: 52.5px;
}
.reportfive {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #494949;
}
.reportsix {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.districtname {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #797979;
}
.districtvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.yearhead {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.yearsub {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
</style>
  